.editDisplay {
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 44px;
    color: #323031;
    max-width: 816px;
    margin: 0 0 10px;
  }
  .editDisplay__logo-edit {
    margin-top: 30px;
    & > div:first-child {
      max-width: 420px;
      display: flex;
      justify-content: flex-start;
      img {
        object-fit: scale-down;
        max-width: 100%;
        max-height: 200px;
        margin: 0 0;
      }
    }
    .upload-logo {
      .upload-image-button {
        position: relative;
        max-width: 420px;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        button {
          height: 100px;
          width: 100%;
          border: 2px dashed lightblue;
          outline: none;
          cursor: pointer;
          background: #dee4e4;
          border-radius: 4px;
          font-size: 16px;
          color: #707070;
          font-weight: 500;
          font-family: Roboto;
          z-index: 0;
        }
        span {
          position: absolute;
          top: 65px;
          color: #707070;
          font-weight: 300;
          z-index: 6;
        }
      }
    }
    .color-picker {
      border-top: 1px solid #d4d8d8;
      padding-top: 15px;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      h2 {
        margin-right: 29px;
      }
    }
  }
}
