@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Roboto/Roboto-Medium.ttf"); /* IE9 Compat Modes */
  src: local("Roboto-Medium"), url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Roboto/Roboto-Thin.ttf"); /* IE9 Compat Modes */
  src: local("Roboto-Thin"), url("../assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Roboto/Roboto-Light.ttf"); /* IE9 Compat Modes */
  src: local("Roboto-Light"), url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf"); /* IE9 Compat Modes */
  src: local("Roboto-Regular"), url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/fonts/Roboto/Roboto-Italic.ttf"); /* IE9 Compat Modes */
  src: local("Roboto-Italic"), url("../assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype"); /* Safari, Android, iOS */
}
