.Toastify__toast--error,
.Toastify__toast--success {
  border-radius: 0px 4px 4px 0px !important;
  font-family: Roboto !important;
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 126.19% !important;
  padding: 10px 20px !important;
}
.Toastify__toast--success {
  background: #daedda !important;
  color: #389f43 !important;
  border-left: 6px solid #6bc079;
}
.Toastify__toast-container {
  width: 400px !important;
}
