.downloadFiles {
  button {
    color: $main-button;
    background: none;
    border: 1px solid $main-button;
    border-radius: 4px;
    font-weight: normal;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s all ease;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover {
      background: $main-button;
      border-color: $main-button;
      color: white;
      @extend %base-color-elements-hover;
    }
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;    
    & > button {
      margin-right: 0px !important;
      margin-top: 10px;
    }
  }
}