.confirm-card {
  .accordionHeader {
    @extend %main-input-text;
  }

  .questions {
    max-width: 540px;
    .questions__item {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 29px;
      }
      &:last-child {
        margin-bottom: 80px;
      }
      p {
        @extend %main-input-text;
        color: #323031;
        line-height: 39px;
        margin: 0;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
      }
      .answerOptions {
        margin-top: -10px;
      }
      .radioButtons {
        @extend %main-input-text;
        display: flex;
        flex-direction: column;

        /* для элемента input c type="radio" */
        .radio > input {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }

        /* для элемента label связанного с .radio */
        .radio > span {
          display: inline-flex;
          align-items: center;
          user-select: none;
          cursor: pointer;
        }

        /* создание в label псевдоэлемента  before со следующими стилями */
        .radio > span::before {
          content: '';
          display: inline-block;
          width: 25px;
          height: 25px;
          flex-shrink: 0;
          flex-grow: 0;
          margin-right: 20px;
          background: #f2f2f2;
          border: 1px solid $main-color;
          box-sizing: border-box;
          border-radius: 50%;
          transition: all 0.2s ease;
        }

        .radio > input:not(:disabled):not(:checked) + span:hover::before {
          border-color: #4d4d4d;
          background: #e0e0e0;
        }

        .radio > input:checked + span::after {
          content: '';
          width: 15px;
          height: 15px;
          margin-left: 5px;
          background: $main-color;
          box-sizing: border-box;
          border-radius: 50%;
          position: absolute;
        }
        .radio.required {
          & > span {
            color: $error-red;
            &:hover {
              color: $main-color;
            }
            &::before {
              border: 1px solid $error-red;
            }
          }
        }
        .invoiceOptions__different-type {
          display: flex;
          flex-direction: column;
          padding-left: 45px;
        }
      }
      @include max-extra-small {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .declaration {
    div:first-child > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 39px;
      text-align: justify;
      color: #323031;
      margin: 0;
      &:last-child {
        margin-top: 20px;
      }
      @include max-extra-small {
        font-size: 1.15rem;
        line-height: 30px;
      }
    }
    .main-checkbox {
      margin-top: 33px;
      @include max-extra-small {
        margin-top: 20px;
      }
    }
    .signature_wrapper {
      margin-top: 30px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      @include max-extra-small {
        flex-direction: column;
        align-items: center;
        .sign__clear {
          margin-top: 20px;
        }
      }
      .sign-block {
        max-width: 597px;
        height: 151px;
        border: 1px solid #707070;
        border-radius: 4px;
        padding: 3px;
        flex-shrink: 1;
        width: 100%;
        .sigContainer {
          height: 100%;
          width: 100%;
          background-color: #fff;
          display: flex;
          overflow: hidden;
        }
      }
      .sign__clear {
        margin-left: 15px;
        flex-shrink: 0;
        button {
          outline: none;
          border: 3px solid $main-button;
          box-sizing: border-box;
          border-radius: 4px;
          color: $main-button;
          cursor: pointer;
          transition: 0.2s all ease;
          padding: 10px;
          &:hover {
            // background: $main-button-hover;
            // border-color: $main-button-hover;
            @extend %base-color-elements-hover;
            color: white;
          }
        }
      }
    }
  }
}
