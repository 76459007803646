.admin {
  display: grid;
  grid-template-columns: 176px auto;
  width: 100%;
  @include max-large {
    grid-template-columns: 136px auto;
  }
  @include max-medium {
    grid-template-columns: 110px auto;
  }
  @include max-small {
    grid-template-columns: 70px auto;
  }
  @include max-extra-small {
    grid-template-columns: 50px auto;
  }
  .sidebar {
    width: 70px;
    padding-top: 100px;
    background: #dae3e7;
    border-radius: 12px 0 0 12px;
    @include max-medium {
      width: 60px;
    }
    @include max-small {
      width: 40px;
    }
    // @include max-extra-small {
    //   width: 20px;
    // }
    .sidebar__sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      & > div {
        height: 100px;
        cursor: pointer;
        button {
          outline: none;
          border: none;
          cursor: pointer;
          background: none;
        }
        a,
        button {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $main-button;
          box-sizing: border-box;
          border-left: 3px solid #dae3e7;
          &.selected {
            background: $main-background;
            border-left: 3px solid $main-button;
          }
          &:hover {
            border-left: 3px solid $main-button;
          }
          svg {
            max-width: 100%;
            @include max-small {
              width: 80%;
            }
          }
        }
        a.editDisplayButton {
          svg {
            width: 42px;
            fill: $main-button;
            @include max-small {
              width: 80%;
            }
          }
        }
        .tooltip {
          z-index: 99999 !important;
        }
      }
    }
  }
  .content {
    padding: 20px 176px 50px 0px;
    @include max-large {
      padding: 20px 130px 20px 0px;
    }
    @include max-medium {
      padding: 20px 80px 20px 0px;
    }
    @include max-small {
      padding: 20px 40px 20px 0;
    }
    @include max-extra-small {
      padding: 20px 20px 20px 0;
    }
    .editTranslation,
    .editQuestions,
    .editDisplay {
      width: 100%;
      &__title {
        margin-bottom: 10px;
        // margin-top: 130px;
        h1 {
          font-style: normal;
          font-weight: 500;
          font-size: 65px;
          line-height: 76px;
          color: #1b1b1b;
          margin-bottom: 10px;
          // margin: 13px 0 16px;
        }
        p {
          font-style: normal;
          font-weight: 300;
          font-size: 28px;
          line-height: 44px;
          color: #323031;
          margin: 0;
          max-width: 816px;
        }
        @include max-large {
          h1 {
            font-size: 58px;
            margin: 10px 0;
          }
          p {
            font-size: 25px;
          }
        }
        @include max-medium {
          // margin-top: 100px;
          h1 {
            font-size: 49px;
            margin: 10px 0 12px;
            line-height: 60px;
          }
          p {
            font-size: 23px;
          }
        }
        @include max-small {
          // margin-top: 80px;
          h1 {
            font-size: 40px;
            // margin: 3px 0 6px;
            line-height: 55px;
          }
          p {
            font-size: 22px;
          }
        }
        @include max-extra-small {
          // margin-top: 70px;
        }
      }
      form {
        .editTranslationsForm,
        .editQuestionsForm {
          & > div:not(:last-child) {
            margin-bottom: 25px;
            @include max-extra-small {
              margin-bottom: 15px;
            }
          }
        }
        input {
          font-family: 'Roboto';
          height: 50px;
          width: 100%;
          background: $main-background;
          border: 1px solid hsl(0, 0%, 80%);
          box-sizing: border-box;
          border-radius: 4px;
          font-weight: 300;
          font-size: 28px;
          color: $main-color;
          outline: none !important;
          padding: 0 15px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          &.errorBorderRed {
            border-color: $error-red;
          }
          &:focus {
            border-color: $main-button;
          }
          @include max-extra-small {
            font-size: 1.15rem;
          }
        }
        label {
          color: #323031;
          line-height: 39px;
          margin: 0;
          margin-bottom: 10px;
          font-weight: 300;
          font-size: 28px;
          @include max-extra-small {
            font-size: 1.25rem;
          }
        }
        div.submit {
          margin-top: 50px;
          display: flex;
          flex-wrap: nowrap;
          button {
            @extend %buttons;
            max-width: 362px;
            width: 100%;
            height: 70px;
          }
          @include max-small {
            flex-wrap: wrap;
          }

          @include max-extra-small {
            margin-top: 25px;
            flex-wrap: wrap;
            button {
              height: 50px !important;
            }
          }
        }
        span.errorRed {
          color: $error-red;
          font-size: 14px;
          font-weight: normal;
        }
      }
      div.submit {
        margin-top: 50px;
        display: flex;
        flex-wrap: nowrap;
        button {
          @extend %buttons;
          max-width: 362px;
          width: 100%;
          height: 70px;
        }
        @include max-small {
          flex-wrap: wrap;
        }

        @include max-extra-small {
          margin-top: 25px;
          flex-wrap: wrap;
          button {
            height: 50px !important;
          }
        }
      }
    }
  }
}
