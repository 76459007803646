.defaultLayout {
  width: 100%;
  padding: 40px 176px 40px;
  @include max-large {
    padding: 20px 130px 40px;
  }
  @include max-medium {
    padding: 20px 80px 40px;
  }
  @include max-small {
    padding: 20px 40px 40px;
  }
  @include max-extra-small {
    padding: 20px 20px 30px;
  }
}
