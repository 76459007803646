@mixin min-extra-large {
  @media only screen and (min-width: 1401px) {
    @content;
  }
}

@mixin max-extra-large {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

@mixin custom-screen {
  @media only screen and (max-width: 1350px) and (min-width: 992px) {
    @content;
  }
}

@mixin min-large() {
  @media only screen and (min-width: 1201px) {
    @content;
  }
}

@mixin max-large {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin min-medium() {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin max-medium() {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin min-small() {
  @media only screen and (min-width: 769px) {
    @content;
  }
}

@mixin max-small() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin min-extra-small() {
  @media only screen and (min-width: 451px) {
    @content;
  }
}

@mixin max-extra-small() {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin min-extra-mobile() {
  @media only screen and (min-width: 375px) {
    @content;
  }
}

@mixin placeholderAllBrowsers($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}
