.completed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  & > div {
    margin-top: 50px;
    h1 {
      font-weight: 500;
      font-size: 65px;
      line-height: 76px;
      color: #1b1b1b;
      margin: 0;
    }
    h2 {
      font-weight: 200;
      font-size: 48px;
      line-height: 56px;
      color: #323031;
      margin-bottom: 10px;
    }
    h3 {
      font-weight: 300;
      font-size: 28px;
      line-height: 44px;
      color: #323031;
      margin-top: 20px;
      max-width: 820px;
    }
    @include max-small {
      h1 {
        font-size: 3.2rem;
        line-height: 3.1rem;
      }
      h2 {
        font-size: 2.3rem;
        line-height: 3rem;
      }
      h3 {
        font-size: 1.7rem;
        line-height: 2.6rem;
      }
    }
    @include max-extra-small {
      h1 {
        font-size: 1.9rem;
        line-height: 2.5rem;
      }
      h2 {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
      h3 {
        font-size: 1.3rem;
        line-height: 1.9rem;
      }
    }
  }
  .qrCode {
    margin-top: 50px;
    width: 200px;
    height: 200px;
    display: none;
    canvas {
      width: 100%;
      height: 100%;
    }
    // @include max-small {
    //   width: 150px;
    //   height: 150px;
    // }
  }
  .completed__pdf {
    margin-top: 20px;
    width: 73%;
    height: fit-content;
    .loadingText {
      text-align: center;

      
    font-size: 20px;    
    color: #323031;
    }
    .pdf-page-container {
      overflow-y: auto;
      max-height: 1075px;
      border: 1px solid #e3e6e7;
      .react-pdf__Document {
        .react-pdf__Page {
          width: 100%;
          &:not(:first-child) {
            margin-top: 15px;
          }
          canvas.react-pdf__Page__canvas {
            max-width: 100% !important;
          }
        }
      }
      @include max-small {
        max-height: 845px;
      }
      @include max-extra-small {
        max-height: 550px;
      }
    }
    .paginator {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      button {
        box-shadow: none;
        border: none;
        font-size: 18px;
        background: $main-button;
        padding: 5px 15px;
        cursor: pointer;
        &:not(:first-child) {
          margin-left: 10px;
        }
        &:hover {
          filter: brightness(1.1);
        }
        &.active {
          filter: brightness(1.5);
        }
      }
    }

    .pdfSaved {
      margin-top: 5px;
      color: grey;
    }
    @include max-extra-large {
      width: 80%;
    }
    @include max-large {
      width: 90%;
      // height: fit-content;
    }
    @include max-medium {
      width: 100%;
      // height: fit-content;
    }
    @include max-extra-small {
      // height: fit-content;
    }
  }
}
