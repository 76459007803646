.header {
  width: 100%;
  background-color: #f2f2f2;
  height: 85px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 206px;
    .logo-dropdown {
      position: relative;
      .logo {
        max-width: 123px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      &:hover .nav-menu-container {
        opacity: 1;
        transform: translate(0, 0);
        visibility: visible;
      }
      .nav-menu-container {
        position: absolute;
        transform: translate(0, 15px);
        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out,
          visibility 0.15s ease-in-out;
        opacity: 0;
        padding-top: 15px;
        text-indent: initial !important;
        visibility: hidden;
        z-index: 5;

        .nav-menu {
          box-shadow: 0px 4px 17px rgba(115, 115, 115, 0.25);
          background: #f2f2f2;
          border-radius: 4px;
          width: 100px;
          padding: 25px 13px 16px;
          position: relative;
          &::before {
            content: '';
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-bottom: 11px solid #f2f2f2;
            position: absolute;
            top: -5px;
            left: 45px;
            transform: translate(0, -50%);
          }
          .nav-item {
            a {
              display: block;
              width: 100%;
              text-align: center;
              font-size: 12px;
              font-family: 'Roboto';
              font-weight: 300;
              color: #929292;
              transition: 0.15s ease-in-out;
              text-decoration: none;
              padding: 3px 0;
              img {
                width: 46px !important;
                margin: 0 auto;
                margin-bottom: 4px;
              }
              p {
                margin: 3% 0 0;
              }
            }
            &:hover {
              a {
                color: black;
              }
            }
          }
        }
      }
    }
    .middleLogo {
      width: 50px;
      img {
        width: 100%;
      }
    }
    @include max-large {
      padding: 0 130px;
    }
    @include max-medium {
      padding: 0 80px;
    }
    @include max-small {
      padding: 0 40px;
    }
    @include max-extra-small {
      padding: 0 20px;
    }
  }
}
