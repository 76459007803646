@import './styles/_mixin';
@import './styles/_toastify';
@import './styles/variables';
* {
  @extend %removePlatformStyling;
}
.app-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  @media only screen and (max-width: 991px) {
  }
  .app {
    min-height: calc(100vh - 60px);
    margin: 30px;
    width: calc(100% - 60px);
    background: #f2f2f2;
    box-shadow: 0px 0px 29px 1px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    &.withHeader {
      min-height: calc(100vh - 60px - 85px);
    }
    @include max-small {
      margin: 30px 0 0 0;
      min-height: 100vh;
    }
    @include max-extra-small {
      margin: 0 0 0 0;
      width: 100%;
    }
  }
}
