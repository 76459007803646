@import "../../styles/variables";

.notFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 50px;
    color: rgb(87, 97, 126);
    margin: 0;
  }
  p {
    font-size: 25px;
    margin: 30px 0 20px;
    color: rgb(54, 60, 78);
  }
  a {
    font-size: 23px;
    text-decoration: none;
    color: $main-button;
    &:hover {
      text-decoration: underline;
    }
  }
  h1,
  p,
  a {
    text-align: center;
  }
}
