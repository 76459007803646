@import './mixin.scss';
// COLORS
$main-background: #f2f2f2;
// $main-button: #0097f4;
$main-button: var(--baseColor);
// $main-button-hover: #1ca7fd;
// $main-button-hover: transparentize(rgba(#0097f4, 0.5), 0.2);
$main-color: #707070;
$main-color-hover: #4e4e4e;
$error-red: #ce0000;

%base-color-elements-hover {
  filter: brightness(110%);
}

%main-input-text {
  font-weight: 300;
  font-size: 28px;
  line-height: 44px;
  color: #707070;
  @include max-extra-small {
    font-size: 1.15rem;
    line-height: 25px;
  }
}

%buttons {
  border: 3px solid $main-button;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 33px;
  background: $main-button;
  color: #f2f2f2;
  cursor: pointer;
  transition: 0.2s all ease;
  &:hover {
    // background: $main-button-hover;
    // border-color: $main-button-hover;
    @extend %base-color-elements-hover;
    color: white;
  }
  &:disabled {
    color: $main-button;
    background: none;
    cursor: auto;
  }
  @include max-extra-small {
    font-size: 1.7rem;
  }
}
%buttons-back {
  color: $main-button;
  background: none;
  border: 3px solid $main-button;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 33px;
  cursor: pointer;
  transition: 0.2s all ease;
  &:hover {
    background: $main-button;
    border-color: $main-button;
    color: white;
    @extend %base-color-elements-hover;
  }
  @include max-extra-small {
    font-size: 1.7rem;
  }
}
%removePlatformStyling {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
