.editTranslation {
  // width: 100%;
  // .editTranslation__title {
  //   margin-bottom: 10px;
  //   // margin-top: 130px;
  //   h1 {
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 65px;
  //     line-height: 76px;
  //     color: #1b1b1b;
  //     // margin: 13px 0 16px;
  //   }
  //   p {
  //     font-style: normal;
  //     font-weight: 300;
  //     font-size: 28px;
  //     line-height: 44px;
  //     color: #323031;
  //     margin: 0;
  //     max-width: 816px;
  //   }
  //   @include max-large {
  //     h1 {
  //       font-size: 58px;
  //       margin: 10px 0;
  //     }
  //     p {
  //       font-size: 25px;
  //     }
  //   }
  //   @include max-medium {
  //     // margin-top: 100px;
  //     h1 {
  //       font-size: 49px;
  //       margin: 10px 0 12px;
  //       line-height: 60px;
  //     }
  //     p {
  //       font-size: 23px;
  //     }
  //   }
  //   @include max-small {
  //     // margin-top: 80px;
  //     h1 {
  //       font-size: 40px;
  //       // margin: 3px 0 6px;
  //       line-height: 55px;
  //     }
  //     p {
  //       font-size: 22px;
  //     }
  //   }
  //   @include max-extra-small {
  //     // margin-top: 70px;
  //   }
  // }

  .editTranslation__languages {
    max-width: 600px;
    display: flex;
    align-items: center;
    .editTranslation__languages_dropdown {
      flex: 0 1 540px;
      .languages_dropdown__control {
        height: 50px;
        background: url("../../assets/img/language.png") no-repeat left 14px center;
        padding-left: 40px;
        font-weight: 300;
        font-size: 28px;
        color: #707070;
      }
      .languages_dropdown__menu {
        background: $main-background;
        margin-top: 3px;

        .languages_dropdown__menu-list {
          max-height: 210px;
          font-style: normal;
          font-weight: 300;
          font-size: 28px;
          color: $main-color;
          .languages_dropdown__option {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      &.errorBorderRed .languages_dropdown__control {
        border-color: $error-red;
        box-shadow: 0 0 5px #ce000054;
      }
    }
    .deleteButton {
      margin-left: 15px;
      cursor: pointer;
      svg {
        color: #707070;
        &:hover {
          color: $error-red;
        }
      }
    }
    @include max-extra-small {
      .start-page__languages_dropdown .languages_dropdown__control {
        height: 45px;
      }
      .start-page__languages_dropdown {
        .languages_dropdown__control,
        .languages_dropdown__menu-list {
          font-size: 24px !important;
        }
      }
    }
  }
  .editTranslationsForm_wrapper {
    margin-top: 50px;
    form {
      .editTranslationsForm {
        // & > div:not(:last-child) {
        //   margin-bottom: 25px;
        //   @include max-extra-small {
        //     margin-bottom: 15px;
        //   }
        // }
        h2 {
          font-size: 30px;
          font-weight: 500;
          margin: 10px 0 30px;
        }
        & > div:not(:first-child) {
          h2 {
            margin-top: 50px;
            @include max-extra-small {
              margin-top: 30px;
              margin-bottom: 10px;
            }
          }
        }
        div.inputAndLabel {
          display: flex;
          align-items: center;
          & > div:first-child {
            flex: 0 1 30%;
            padding-right: 15px;
          }
          & > div:last-child {
            flex: 0 1 70%;
          }
          @include max-extra-small {
            flex-direction: column;
            align-items: flex-start;
            & > div {
              width: 100%;
            }
          }
        }
        // input {
        //   font-family: "Roboto";
        //   height: 50px;
        //   width: 100%;
        //   background: $main-background;
        //   border: 1px solid hsl(0, 0%, 80%);
        //   box-sizing: border-box;
        //   border-radius: 4px;
        //   font-weight: 300;
        //   font-size: 28px;
        //   color: $main-color;
        //   outline: none !important;
        //   padding: 0 15px;
        //   white-space: nowrap;
        //   overflow-x: hidden;
        //   text-overflow: ellipsis;
        //   &.errorBorderRed {
        //     border-color: $error-red;
        //   }
        //   &:focus {
        //     border-color: $main-button;
        //   }
        //   @include max-extra-small {
        //     font-size: 1.15rem;
        //   }
        // }
        // label {
        //   color: #323031;
        //   line-height: 39px;
        //   margin: 0;
        //   margin-bottom: 10px;
        //   font-weight: 300;
        //   font-size: 28px;
        //   @include max-extra-small {
        //     font-size: 1.25rem;
        //   }
        // }
      }
      // div.submit {
      //   margin-top: 50px;
      //   display: flex;
      //   flex-wrap: nowrap;
      //   button {
      //     @extend %buttons;
      //     max-width: 362px;
      //     width: 100%;
      //     height: 70px;
      //   }
      //   @include max-small {
      //     flex-wrap: wrap;
      //   }

      //   @include max-extra-small {
      //     margin-top: 25px;
      //     flex-wrap: wrap;
      //     button {
      //       height: 50px !important;
      //     }
      //   }
      // }
    }
  }
  // span.errorRed {
  //   color: $error-red;
  //   font-size: 14px;
  //   font-weight: normal;
  // }
}
