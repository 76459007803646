.start-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .start-page__logo {
    max-width: 650px;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    // margin-top: 20px;
    @include max-extra-small {
      width: 100%;
    }
    img {
      object-fit: scale-down;
      max-width: 100%;
      max-height: 250px;
      margin: 0 0;
    }
  }
  .start-page__title {
    margin-bottom: 40px;
    margin-top: 115px;
    h2 {
      font-style: normal;
      font-weight: 100;
      font-size: 48px;
      line-height: 56px;
      color: #323031;
      margin: 0;
    }
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 65px;
      line-height: 76px;
      color: #1b1b1b;
      margin: 13px 0 16px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 28px;
      line-height: 44px;
      color: #323031;
      margin: 0;
      max-width: 816px;
    }
    @include max-large {
      h2 {
        font-size: 37px;
      }
      h1 {
        font-size: 58px;
        margin: 5px 0 10px;
      }
      p {
        font-size: 25px;
      }
    }
    @include max-medium {
      margin-top: 100px;
      h2 {
        font-size: 33px;
      }
      h1 {
        font-size: 49px;
        margin: 10px 0 12px;
        line-height: 60px;
      }
      p {
        font-size: 23px;
      }
    }
    @include max-small {
      margin-top: 80px;
      h1 {
        font-size: 40px;
        margin: 3px 0 6px;
        line-height: 55px;
      }
      p {
        font-size: 22px;
      }
    }
    @include max-extra-small {
      margin-top: 70px;
    }
  }

  .start-page__languages {
    max-width: 540px;
    .start-page__languages_dropdown {
      .languages_dropdown__control {
        height: 50px;
        background: url('../../assets/img/language.png') no-repeat left 14px center;
        padding-left: 40px;
        font-weight: 300;
        font-size: 28px;
        color: #707070;
        &--is-focused {
          border-color: $main-button;
        }
      }
      .languages_dropdown__menu {
        background: $main-background;
        margin-top: 3px;

        .languages_dropdown__menu-list {
          max-height: 160px;
          font-style: normal;
          font-weight: 300;
          font-size: 28px;
          color: $main-color;
          .languages_dropdown__option {
            display: flex;
            justify-content: space-between;
            &--is-selected {
              background-color: $main-button;
            }
          }
        }
      }
      &.errorBorderRed .languages_dropdown__control {
        border-color: $error-red;
        box-shadow: 0 0 5px #ce000054;
      }
    }
    @include max-extra-small {
      .start-page__languages_dropdown .languages_dropdown__control {
        height: 45px;
      }
      .start-page__languages_dropdown {
        .languages_dropdown__control,
        .languages_dropdown__menu-list {
          font-size: 24px !important;
        }
      }
    }
  }
  .start-page__start-button {
    margin-top: 53px;
    button {
      background: #f2f2f2;
      border: 3px solid $main-button;
      box-sizing: border-box;
      border-radius: 4px;
      // width: 362px;
      // height: 70px;
      padding: 13px 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 33px;
      line-height: 39px;
      background: $main-button;
      color: #f2f2f2;
      cursor: pointer;
      transition: 0.2s all ease;
      &:disabled {
        color: $main-button;
        background: none;
        cursor: auto;
      }
      &:hover:not(:disabled) {
        // background: $main-button-hover;
        // border-color: $main-button-hover;
        @extend %base-color-elements-hover;
        color: white;
      }
    }
    @include max-extra-small {
      margin-top: 30px;
      button {
        width: 100%;
        font-size: 30px;
      }
    }
  }
}
