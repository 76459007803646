.profile,
.confirmation {
  span.errorRed {
    color: $error-red;
    font-size: 14px;
    font-weight: normal;
  }
  .dropdown__control,
  input.mainInput {
    font-family: 'Roboto';
    height: 50px;
    background: $main-background;
    border: 1px solid $main-color;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 300;
    font-size: 28px;
    color: $main-color;
    outline: none !important;
    &:focus {
      border-color: $main-button;
    }

    @include max-extra-small {
      font-size: 1.15rem;
    }
  }
  input.mainInput {
    padding: 0 15px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    @include placeholderAllBrowsers($main-color);
    &.errorBorderRed {
      border-color: $error-red;
    }
    &[type='date'] {
      &::placeholder {
        display: none;
      }
      &::-webkit-calendar-picker-indicator {
        opacity: 0.4;
      }
    }
  }
  .main_dropdown {
    &.errorBorderRed .dropdown__control {
      border-color: $error-red;
    }
    .dropdown__control {
      box-shadow: none;
      padding-left: 15px;
      &--is-focused {
        border-color: $main-button;
      }
    }
    .dropdown__value-container {
      padding: 0;
      height: 100%;
      .dropdown__placeholder {
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      .dropdown__single-value {
        color: $main-color;
      }
    }
    .dropdown__menu {
      background: $main-background;
      margin-top: 2px;

      .dropdown__menu-list {
        font-weight: 300;
        font-size: 22px;
        color: $main-color;
        .dropdown__option {
          &--is-selected {
            background-color: $main-button;
          }
        }
      }
    }
  }
  .main-checkbox {
    @extend %main-input-text;
    // @include max-extra-small {
    //   line-height: 30px;
    // }
    .checkbox > input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .checkbox > span {
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
    }
    .checkbox > span::before {
      content: '';
      display: inline-block;
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 20px;
      background: #f2f2f2;
      border: 1px solid $main-color;
      box-sizing: border-box;
      border-radius: 4px;
      transition: all 0.2s ease;
    }
    .checkbox > input:not(:disabled):not(:checked) + span:hover::before {
      border-color: #4d4d4d;
      background: #e0e0e0;
    }
    .checkbox > input:checked + span::before {
      background-image: url('../assets/img/checkobx-checked.svg');
      background-repeat: no-repeat;
    }
    .checkbox.required {
      & > span {
        color: $error-red;
        &:hover {
          color: darkred;
          &::before {
            border-color: darkred !important;
          }
        }
        &::before {
          border: 1px solid $error-red;
        }

      }
    }
  }
  div.nextStep_button {
    margin-top: 50px;
    display: flex;
    flex-wrap: nowrap;
    button.nextStep {
      @extend %buttons;
      max-width: 362px;
      width: 100%;
      height: 70px;
      svg {
        margin-right: 10px;
      }
    }
    button.back {
      @extend %buttons-back;
      max-width: 362px;
      width: 100%;
      height: 70px;
      margin-right: 20px;
      svg {
        margin-right: 10px;
      }
    }
    @include max-small {
      flex-wrap: wrap;
      button {
        &:first-of-type {
          margin-bottom: 20px;
        }
        &.back {
          margin-right: 0;
        }
      }
    }

    @include max-extra-small {
      margin-top: 25px;
      flex-wrap: wrap;
      button {
        height: 50px !important;
        &:first-of-type {
          margin-bottom: 10px;
        }
        &.back {
          margin-right: 0;
        }
      }
    }
  }
}
