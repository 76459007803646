.сardScanner {
  margin-bottom: 40px;
  .сardScanner__button {
    & > div {
      width: fit-content;
      input {
        display: none;
        z-index: 1;
        & + label {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          padding-right: 10px;

          span.сardScanner__title {
            font-size: 28px;
            line-height: 155.69%;
            color: $main-color;
            margin-left: 10px;
            z-index: 5;
          }
          img {
            z-index: 5;
          }
          span.сardScanner__scanning-percentage {
            display: block;
            background-color: #c3f7c3;
            // width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: 1;
          }
        }
      }

      img.photo-block__photo {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }
}
