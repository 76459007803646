.profile {
  // max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > div > h1 {
    font-weight: 500;
    font-size: 65px;
    line-height: 76px;
    color: #1b1b1b;
    @include max-extra-small {
      font-size: 1.9rem;
      line-height: 1.9rem;
      margin: 10px 0;
    }
  }
}
