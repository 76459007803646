.patientInformation-card {
  // width: 540px;
  .accordionHeader {
    @extend %main-input-text;
  }

  .patientInformation-wrapper {
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    .patientInformation {
      max-width: 540px;
      .patientInformation__mainInform {
        display: flex;
        flex-direction: column;
        margin-bottom: 46px;
        & > div {
          display: flex;
          justify-content: space-between;
          &:not(:last-child) {
            margin-bottom: 34px;
          }
          input {
            width: 100%;
          }
          & > div:last-child:not(:first-child) {
            margin-left: 20px;
          }
          & > div:first-of-type {
            flex: 0 1 50%;
          }
          & > div:last-of-type {
            flex: 0 1 50%;
          }
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
        @include max-extra-small {
          margin-bottom: 30px;
          & > div {
            flex-wrap: wrap;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            & > div {
              flex: 0 1 100% !important;
              // margin-left: 0 !important;
              &:last-child {
                margin-left: 0px !important;
                margin-top: 20px;
              }
            }
          }
        }
      }
      .patientInformation__secondBlock {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        input {
          width: 100%;
        }
        & > div:not(:last-child) {
          margin-bottom: 34px;
        }
        @include max-extra-small {
          & > div:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        .patientInformation__insuranse-custom-info {
          display: flex;
          flex-direction: column;
          & > div {
            display: flex;
            justify-content: space-between;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            input {
              width: 100%;
            }
            & > div {
              flex: 0 0 100%;
            }
            & > div:nth-child(2),& > div:nth-child(3) {
              margin-left: 20px;
            }
            &:nth-child(2) {
              div:first-of-type {
                flex: 0 2 410px;
              }
              div:last-of-type {
                flex: 0 1 110px;
              }
            }
            &:nth-child(3) {
              // div:first-of-type {
              //   flex: 0 1 200px;
              // }
              // div:last-of-type {
              //   flex: 0 1 320px;
              // }
              &>div:nth-child(1) {
                flex: 0 1 30%;
              }
              &>div:nth-child(2) {
                flex: 0 1 30%;
              }
              &>div:nth-child(3) {
                flex: 0 1 40%;
              }
            }
          }
          @include max-extra-small {
            & > div {
              flex-wrap: wrap;
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              & > div {
                flex: 0 1 100% !important;
                &:last-child:not(:first-child) {
                  margin-left: 0px;
                  margin-top: 20px;
                }
              }
            }
          }
        }
        .insurance_select {
          .dropdown__menu-list {
            & > div:nth-last-child(2) {
              border-top: 1px solid #c2c7ca;
              // margin-top: 10px;
              // padding-top: 12px;
              // padding-bottom: 12px;
            }
          }
        }
      }
      .patientInformation__invoiceBlock {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        p {
          margin: 0;
          @extend %main-input-text;
        }

        .invoiceOptions {
          @extend %main-input-text;
          display: flex;
          flex-direction: column;

          /* для элемента input c type="radio" */
          .invoiceRadio > input {
            position: absolute;
            z-index: -1;
            opacity: 0;            
            &:disabled {
              &+span {
              color: #b4b4b4;
              &::before{
                background: #d8d8d8;
              }
            }
          }
          }

          /* для элемента label связанного с .invoiceRadio */
          .invoiceRadio > span {
            display: inline-flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
          }


          /* создание в label псевдоэлемента  before со следующими стилями */
          .invoiceRadio > span::before {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 20px;
            background: #f2f2f2;
            border: 1px solid $main-color;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 0.2s ease;
          }

          .invoiceRadio > input:not(:disabled):not(:checked) + span:hover::before {
            border-color: #4d4d4d;
            background: #e0e0e0;
          }

          .invoiceRadio > input:checked + span::before {
            background-image: url('../../assets/img/checkobx-checked.svg');
          }
          .invoiceOptions__different-type {
            display: flex;
            flex-direction: column;
            padding-left: 45px;
          }
        }
        .optional {
          width: 100%;
          margin-top: 65px;
          input {
            width: 100%;
          }
          .contactsPersonDate {
            & > div {
              flex: 0 1 100%;
              display: flex;
              flex-direction: column;
            }
          }
        }
        @include max-extra-small {
          margin-top: 30px;
          .invoiceOptions {
            width: 100%;
            margin-left: 20px;
            margin-top: 10px;
          }
          .optional {
            margin-top: 30px;
          }
        }
      }
    }
    .photo-block {
      & > div {
        position: relative;
        input {
          display: none;
          z-index: 1;
          & + label {
            position: relative;
            display: block;
            width: 230px;
            height: 220px;
            background: #d2d2d2;
            background-image: url('../../assets/img/camera 1.png');
            background-repeat: no-repeat;
            background-position: center 46%;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 4px;
            &.photo_taken {
              background: none;
            }
          }
        }
      }
      img.photo-block__photo {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      margin-left: 130px;
    }
    @include max-medium {
      flex-direction: column;
      .patientInformation {
        order: 2;
      }
      .photo-block {
        max-width: 540px;
        margin: 0 0 40px 0;
        order: 1;
        display: flex;
        justify-content: center;
      }
    }
  }
  .contacts_mainInform {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      input {
        width: 100%;
      }
      & > div {
        flex: 0 0 100%;
      }
      & > div:nth-child(2), & > div:nth-child(3) {
        margin-left: 20px;
      }
      &.contacts_mainInform {
        &__second {
          div:first-of-type {
            flex: 0 2 410px;
          }
          div:last-of-type {
            flex: 0 1 110px;
          }
        }
        &__third {
          &>div:nth-child(1) {
            flex: 0 1 30%;
          }
          &>div:nth-child(2) {
            flex: 0 1 30%;
          }
          &>div:nth-child(3) {
            flex: 0 1 40%;
          }
        }
        &__fourth {
          div:first-of-type {
            flex: 0 1 245px;
          }
          div:last-of-type {
            flex: 0 1 275px;
          }
        }
        // &_sixth {
        //   & > div:first-of-type {
        //     flex: 0 1 200px;
        //   }
        //   & > div:last-of-type {
        //     flex: 0 2 320px;
        //   }
        // }
      }
    }
    @include max-extra-small {
      & > div {
        flex-wrap: wrap;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        & > div {
          flex: 0 1 100% !important;
          &:not(:first-child) {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .contacts {
    max-width: 540px;
    .contacts__secondBlock {
      margin-top: 30px;
      // margin-bottom: 50px;
      .contacts__representative-form {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        & > div {
          display: flex;
          justify-content: space-between;
          &:not(:last-child) {
            margin-bottom: 34px;
          }
          input {
            width: 100%;
          }
          & > div {
            flex: 0 0 100%;
          }
          & > div:nth-child(2),& > div:nth-child(3) {
            margin-left: 20px;
          }
          &:nth-child(1),
          &:nth-child(2) {
            & > div:first-of-type {
              flex: 0 1 50%;
            }
            & > div:last-of-type {
              flex: 0 1 50%;
            }
          }
          &:nth-child(3) {
            div:first-of-type {
              flex: 0 2 410px;
            }
            div:last-of-type {
              flex: 0 1 110px;
            }
          }
          &:nth-child(4) {
            &>div:nth-child(1) {
              flex: 0 1 30%;
            }
            &>div:nth-child(2) {
              flex: 0 1 30%;
            }
            &>div:nth-child(3) {
              flex: 0 1 40%;
            }
          }
        }
      }
    }
    @include max-extra-small {
      .contacts__secondBlock {
        margin-top: 30px;
        .contacts__representative-form {
          & > div {
            flex-wrap: wrap;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            // &.countryLine {
            //   &>div:nth-child(1) {
            //     flex: 0 1 30%;
            //   }
            //   &>div:nth-child(2) {
            //     flex: 0 1 30%;
            //   }
            //   &>div:nth-child(3) {
            //     flex: 0 1 40%;
            //   }
            // }
            & > div {
              flex: 0 0 100% !important;
              // &:not(:last-child) {
              //   margin-bottom: 20px;
              // }
              &:last-child:not(:first-child) {
                margin-left: 0px;
                margin-top: 20px;
              }
              
            }
          }
        }
      }
    }
  }
}
