.auth_wrapper {
  width: 100%;
  padding: 20px 176px 50px;
  @include max-large {
    padding: 20px 130px;
  }
  @include max-medium {
    padding: 20px 80px;
  }
  @include max-small {
    padding: 20px 40px;
  }
  @include max-extra-small {
    padding: 20px 20px;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .auth {
    margin-bottom: 20px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 65px;
      line-height: 76px;
      color: #1b1b1b;
      margin-bottom: 10px;
      margin-top: 0;
      @include max-extra-small {
        font-size: 2.5rem;
      }
    }
    form {
      margin-top: 30px;
      @include max-extra-small {
        margin-top: 15px;
      }
      & > div:not(:last-child) {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        @include max-extra-small {
          margin-bottom: 15px;
        }
      }
      input {
        font-family: 'Roboto';
        height: 50px;
        width: 100%;
        background: $main-background;
        border: 1px solid hsl(0, 0%, 80%);
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 300;
        font-size: 28px;
        color: $main-color;
        outline: none !important;
        padding: 0 15px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        &.errorBorderRed {
          border-color: $error-red;
        }
        &:focus {
          border-color: $main-button;
        }
        @include max-extra-small {
          font-size: 1.15rem;
        }
      }
      label {
        color: #323031;
        line-height: 39px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 28px;
        @include max-extra-small {
          font-size: 1.25rem;
        }
      }
      div.submit {
        margin-top: 50px;
        // display: flex;
        width: 100%;
        max-width: 350px;
        button {
          @extend %buttons;
          width: 100%;
          height: 70px;
        }
        @include max-small {
          flex-wrap: wrap;
        }

        @include max-extra-small {
          margin-top: 25px;
          flex-wrap: wrap;
          button {
            height: 50px !important;
          }
        }
        span.errorRed {
          display: inline-block;
          width: 100%;
          text-align: center;
          color: $error-red;
          font-size: 14px;
          font-weight: normal;
          margin-top: 10px;
        }
      }
      span.errorRed {
        color: $error-red;
        font-size: 14px;
        font-weight: normal;
        margin-top: 5px;
      }
    }
  }
}
