.editQuestions {
  .editQuestions__questions {
    margin-top: 50px;
    form {
      .editQuestionsForm {
        div.questions__item {
          button.delQuestion {
            outline: none;
            border: 3px solid #707070;
            box-sizing: border-box;
            border-radius: 4px;
            color: #707070;
            cursor: pointer;
            transition: 0.2s all ease;
            height: 50px;
            width: 50px;
            box-sizing: border-box;
            &:hover {
              background: $error-red;
              border-color: $error-red;
              color: white;
            }
          }
          .question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > div {
              &:nth-child(1) {
                flex: 0 0 3%;
                margin-right: 10px;
              }
              &:nth-child(2) {
                flex: 0 1 90%;

                .input {
                  position: relative;
                  input {
                    padding-right: 150px;
                  }
                  .input__buttons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    button {
                      height: 100%;
                      width: 50px;
                      outline: none;
                      border: 1px solid #cccccc;
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      &:not(:first-child) {
                        border-left: none;
                      }
                      svg {
                        transition: fill 0.2s ease;
                        fill: #cccccc;
                      }
                      &.active svg {
                        fill: #484c93;
                      }
                    }
                  }
                }
              }
              &:nth-child(3) {
                flex: 0 1 7%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: 10px;
                min-width: 50px;
              }
            }
          }
          .predefinedAnswer {
            // margin-left: 10px;
            margin-top: 10px;
            width: 100%;

            ul {
              margin: 0;
              padding-left: 0;
              box-sizing: border-box;
              list-style: none;
              li {
                div {
                  display: flex;
                  justify-content: space-between;
                  & > div {
                    &:nth-child(1) {
                      flex: 0 1 3%;
                      margin-right: 10px;
                    }
                    &:nth-child(2) {
                      flex: 0 1 90%;
                      input {
                        width: 100%;
                        margin-left: 30px;
                      }
                    }
                    &:nth-child(3) {
                      flex: 0 1 7%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-end;
                      margin-left: 10px;
                      min-width: 50px;
                      button.delQuestion {
                        border: none;

                        color: $error-red;
                        cursor: pointer;
                        background: none;
                        &:hover {
                          background: $error-red;
                          border-color: $error-red;
                          color: white;
                        }
                      }
                    }
                  }
                }
              }
              li:not(:first-child) {
                margin-top: 10px;
              }
            }
            .addAnswerOption {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-top: 10px;
              // width: 93%;
              // padding-right: calc(7%);
              div:first-child {
                button {
                  // margin-right: 10px;
                  outline: none;
                  border: 1px solid $main-button;
                  box-sizing: border-box;
                  border-radius: 4px;
                  color: $main-button;
                  cursor: pointer;
                  transition: 0.2s all ease;
                  box-sizing: border-box;
                  font-size: 16px;
                  padding: 10px;
                  &:hover {
                    background: $main-button;
                    border-color: $main-button;
                    color: white;
                  }
                }
              }
              div:last-child {
                flex: 0 1 7%;
                margin-left: 10px;
                min-width: 50px;
              }
            }
          }
        }

        div.addQuestion {
          display: flex;
          justify-content: center;
          button {
            outline: none;
            border: 3px solid $main-button;
            box-sizing: border-box;
            border-radius: 4px;
            color: $main-button;
            cursor: pointer;
            transition: 0.2s all ease;
            height: 50px;
            width: 100px;
            box-sizing: border-box;
            &:hover {
              background: $main-button;
              border-color: $main-button;
              color: white;
            }
          }
        }
      }
    }
  }
}
